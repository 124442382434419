var ajaxRemovalActive = false;
var inMobile = false;
var mobileCartWidth = 1400;

(function ($) {
  $(document).ready(function () {
    if ($(window).innerWidth() < mobileCartWidth) {
      inMobile = true;
    }

    //No need once implement auto load
    //loadFragments();

    //header data pool
    let data = JSON.parse($('script[type="text/json"]#auto-load-data').text());
    updateProductBars(data["cart-content"]);
    $(".cart-desc").text("$" + data["cart-total"]["amt"] + " Offer");
    $(".cart-desc").append(
      `<img src="${data["cart-total"]["img"]}" class="ms-2" style="height: 30px;" alt="Go to checkout" />`
    );
    $(".cart-desc-offcanvas > p").first().remove();
    $(".cart-desc-offcanvas").prepend(
      `<p>$${data["cart-total"]["amt"]} Offer</p>`
    );

    document.addEventListener("updateProductBarsEvent", function (e) {
      updateProductBars(e.detail); // Use the passed data
    });

    $(window).on("resize", () => {
      if ($(window).innerWidth() < mobileCartWidth) {
        inMobile = true;
      } else {
        inMobile = false;
      }
      updateProductBars(null, inMobile);
    });

    $(document).on("click", ".cart-offcanvas-button", function () {
      $(document).trigger("close-all");
    });

    $(document).on("click", ".tradein-remove-button", function () {
      let itemNode = $(this).parent().parent().parent();
      removeItemFromCart(itemNode);
    });

    $(document).on("click", ".tradein-quantity-remove", function () {
      let nodeParent = $(this).parent().parent().parent().parent();
      changeItemQuantity(nodeParent, -1);
    });

    $(document).on("click", ".tradein-quantity-add", function () {
      let nodeParent = $(this).parent().parent().parent().parent();
      changeItemQuantity(nodeParent, 1);
    });
  });

  function changeItemQuantity(node, change) {
    let itemKey = $(node).attr("item-key");
    var ajaxData = {
      action: "tradein_quantity_change",
      nonce: $("#df-ajax-nonce").val(),
      item_key: itemKey,
      quantity_change: change,
    };

    if (ajaxRemovalActive == true) {
      //Multi-request prevention
      return;
    }

    ajaxRemovalActive = true;

    $(node).find(".load-modal").addClass("visible");

    $.ajax({
      type: "post",
      url: $("#df-ajax-url").val(),
      data: ajaxData,
      success: function (response) {
        if (response.error) {
          console.log("ERROR! " + response.error);
        } else {
          $(".cart-desc").replaceWith(response["fragments"]["a.cart-desc"]);
          $(".cart-desc-offcanvas").replaceWith(
            response["fragments"]["button.cart-desc-offcanvas"]
          );

          updateProductBars(response["fragments"]["cart-content"], inMobile);
        }
        ajaxRemovalActive = false;
      },
      complete: function () {
        ajaxRemovalActive = false;
        $(`[item-key='${itemKey}']`).find(".load-modal").removeClass("visible");
      },
    });
  }

  function removeItemFromCart(itemBar) {
    let itemKey = itemBar.attr("item-key");

    var ajaxData = {
      action: "product_remove",
      nonce: $("#df-ajax-nonce").val(),
      item_key: itemKey,
    };

    if (ajaxRemovalActive == true) {
      //Multi-request prevention
      return;
    }

    ajaxRemovalActive = true;

    $(itemBar).find(".load-modal").addClass("visible");

    $.ajax({
      type: "post",
      url: $("#df-ajax-url").val(),
      data: ajaxData,
      success: function (response) {
        if (response.error) {
          console.log("Error removing item from cart: " + response.error);
        } else {
          $(".cart-desc").replaceWith(response["fragments"]["a.cart-desc"]);
          $(".cart-desc-offcanvas").replaceWith(
            response["fragments"]["button.cart-desc-offcanvas"]
          );

          updateProductBars(response["fragments"]["cart-content"], inMobile);
        }
        ajaxRemovalActive = false;
      },
      complete: function () {
        ajaxRemovalActive = false;
        itemBar.find(".load-modal").removeClass("visible");
      },
    });
  }
})(jQuery);

export function updateProductBars(items) {
  (function ($) {
    //Null on pre-ajax load
    if (items != null) {
      $("#offer-box-title").css("display", "none");

      $(".tradein-form-trade-item").each(function () {
        let safe = false;

        for (let i = 0; i < items.length; i++) {
          if (items[i]["key"] == $(this).attr("item-key")) {
            safe = true;
          }
        }

        if (safe == false) {
          $(this).remove();
        }
      });
    }

    let mobileTarget = $(".offcanvas-cart-content-wrapper");
    let desktopTarget = $("#tradein-offer-box");

    if (inMobile == true) {
      if (desktopTarget.length > 0) {
        if ($(desktopTarget).children().length > 0) {
          $(".tradein-form-trade-item").each(function () {
            let newMobileBar = $(this).clone();
            $(newMobileBar)
              .find(".tradein-form-trade-item-quote-amount")
              .text(`$${$(newMobileBar).attr("prev-price")}`);
            $(newMobileBar).appendTo(mobileTarget);
            $(this).remove();
          });
        }
      }
    } else {
      //Hide offcanvas; not in mobile
      $("#CartOffcanvas").offcanvas("hide");
      if ($(mobileTarget).children().length > 0) {
        //Reverse to maintain order
        $($(".tradein-form-trade-item").get().reverse()).each(function () {
          let newDesktopBar = $(this).clone();
          $(newDesktopBar).prependTo(desktopTarget);
          $(this).remove();
        });
      }
    }

    if (items == null) {
      return; // Update-only function guard statement
    }

    let totalQuote = 0;

    //Loop through items and update or create
    for (let i = 0; i < items.length; i++) {
      //Check if item already exists
      let itemSelector = $("[item-key='" + items[i]["key"] + "']");

      totalQuote +=
        parseInt(items[i]["quantity"]) * parseInt(items[i]["price"]);

      //If item exists, update quantity and price
      if ($(itemSelector).length > 0) {
        let thisItem = itemSelector[0];

        let newQuantity = parseInt(items[i]["quantity"]);
        let newPrice = parseInt(items[i]["price"]);

        if (
          $(thisItem).attr("prev-price") != newQuantity * newPrice ||
          $(thisItem).attr("prev-price") == null
        ) {
          //Animate all bars
          $({ Counter: $(thisItem).attr("prev-price") }).animate(
            { Counter: newQuantity * newPrice },
            {
              duration: 1000,
              easing: "swing",
              step: function () {
                $(thisItem)
                  .find(".tradein-form-trade-item-quote-amount")
                  .text(`$${Math.round(this.Counter)}`);
              },
              complete: function () {
                $(
                  $(thisItem).find(".tradein-form-trade-item-quote-amount")
                ).text(`$${newQuantity * newPrice}`);
              },
            }
          );
        }
        $(thisItem).attr("prev-price", newQuantity * newPrice);

        $(thisItem).find(".quantity").html(items[i]["quantity"]);
      } else {
        // Create new item
        addProductBar(items[i]);
      }
    }

    //Check if no items then display message
    if (items != null) {
      if ($(".tradein-form-trade-item").length == 0) {
        $("#cta-cart-title").text(
          "You don't have any items in your offer list."
        );
        $(".cta-cart-button").css("display", "flex");

        $("#offer-box-title-header").text("Your offer list will appear here.");

        $("#offer-box-title-subheader").text(
          "Add items with the form to get started."
        );
        $("#offer-box-title").css("display", "flex");

        $(".offcanvas-cart-cta").css("display", "block");
        $(".offcanvas-cart-button").css("display", "none");
      } else {
        $("#offer-box-title").css("display", "none");
        $(".offcanvas-cart-button").css("display", "block");
        $(".offcanvas-cart-cta").css("display", "none");
      }
    }

    if ($(".tradein-form-trade-item").length == 0) {
      $(".offer-box-actions").css("height", "0px");
    } else {
      $("#offer-action-text").text(`Awesome! $${totalQuote}.00 total offer.`);
      $(".offcanvas-cart-title").text(`$${totalQuote}.00 total offer`)
      $(".offer-box-actions").css("height", "45px");
    }
  })(jQuery);

  return false;
}

function addProductBar(newItem) {
  (function ($) {
    let template = $(".tradein-form-trade-item-template");
    let mobileTarget = $(".offcanvas-cart-content-wrapper");
    let desktopTarget = $("#tradein-offer-box");

    let prettyName = newItem["pretty name"];
    let badgeAttributes = newItem["attributes"];
    let typeImageUrl = newItem["image"];
    let quantity = parseInt(newItem["quantity"]);
    let price = parseInt(newItem["price"]);
    let cloneTarget = template;
    let newBar = $(cloneTarget)
      .clone()
      .removeClass("tradein-form-trade-item-template")
      .addClass("tradein-form-trade-item")
      .show(); // show in case display none

    let newBarDesc = $(newBar).find(".tradein-form-trade-item-description");
    let newBarQuantity = $(newBar).find(".quantity");
    let newBarBadgeWrapper = $(newBar).find(".badge-wrapper");
    let newBarBadge = $(newBar).find(".badge-template");

    for (let i = 0; i < badgeAttributes.length; i++) {
      let newBadge = $(newBarBadge).clone().removeClass("badge-template");
      $(newBadge).find(".badge-text").html(badgeAttributes[i]);
      $(newBadge).appendTo(newBarBadgeWrapper);
    }

    $(newBarBadge).remove();

    $(newBar).attr("item-key", newItem["key"]);
    $(newBar).attr("prev-price", quantity * price);
    $(newBarDesc).html(`${prettyName}`);
    $(newBarQuantity).html(quantity);
    $(newBar)
      .find(".tradein-form-trade-item-icon-svg")
      .attr("src", typeImageUrl);

    if(price != 0) {
      $(newBar).find(".price-zero-header").remove();
    }

    if (inMobile == true) {
      //Mobile
      $(newBar).prependTo(mobileTarget);
      $(newBar)
        .find(".tradein-form-trade-item-quote-amount")
        .html(`$${quantity * price}`);
    } else {
      //Non-home page; update mobile cart
      $(newBar)
        .find(".tradein-form-trade-item-quote-amount")
        .html(`$${quantity * price}`);

      //Desktop
      $(newBar).appendTo(desktopTarget);
    }

    setTimeout(function () {
      newBar.css({
        transform: "translateY(0)", // Move to final position
        opacity: 1, // Fade to full opacity
      });
    }, 100);
  })(jQuery);
}
